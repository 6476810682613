import ApiService from "@/common/api.service";
import { SET_POST, SET_POSTS, SET_VISITED_COUNTRIES, SET_IMAGES } from "./mutations.type";
import { GET_POST, GET_POSTS, GET_VISITED_COUNTRIES, GET_IMAGES } from "./actions.type";

const state = {
    posts: [],
    post: {},
    postsLoaded: false,
    countries: [],
    countriesLoaded: false,
    images: [],
    imagesLoaded: false
};

const getters = {
    post: state => state.post,
    posts: state => state.posts,
    latestPosts: state => state.posts.slice(0, 3),
    postsLoaded: state => state.postsLoaded,
    findPosts: state => (query) => {
        var filteredPosts = state.posts.slice();
        if ('country' in query) {
            filteredPosts = filteredPosts.filter(post => post.country.includes(query.country));
        }
        if ('query' in query) {
            filteredPosts = filteredPosts.filter(post => {
                if ((post.tags !== null && post.tags.toLowerCase().includes(query.query.toLowerCase())) 
                    || post.title.toLowerCase().includes(query.query.toLowerCase())) {
                    return post;
                }
            });
        }
        return filteredPosts;
    },
    countries: state => state.countries,
    countriesLoaded: state => state.countriesLoaded,
    images: state => state.images,
    imagesLoaded: state => state.imagesLoaded,
};

const actions = {
    async [GET_POST](context, slug) {
        const { data } = await ApiService.get("post", slug);
        context.commit(SET_POST, { data });
        return data;
    },
    async [GET_POSTS](context) {
        if (!state.postsLoaded) {
            const { data } = await ApiService.get("post");
            context.commit(SET_POSTS, { data });
            return data;
        } else {
            return state.posts;
        }
    },
    async [GET_VISITED_COUNTRIES](context, query) {
        if (!state.countriesLoaded) {
            const { data } = await ApiService.query("countries", query);
            context.commit(SET_VISITED_COUNTRIES, { data });
            return data;
        } else {
            return state.countries;
        }
    },
    async [GET_IMAGES](context, query) {
        if (!state.imagesLoaded) {
            const { data } = await ApiService.query("images", query);
            context.commit(SET_IMAGES, { data });
            return data;
        } else {
            return state.images;
        }
    }
};

const mutations = {
    [SET_POST](state, { data }) {
        state.post = data;
    },
    [SET_POSTS](state, { data }) {
        state.posts = data;
        state.postsLoaded = true;
    },
    [SET_VISITED_COUNTRIES](state, { data }) {
        state.countries = [{continent: '', id: '', name: "All Countries"}, ...data];
        state.countriesLoaded = true;
    },
    [SET_IMAGES](state, { data }) {
        state.images = data;
        state.imagesLoaded = true;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
