<template>
<svg viewBox="-75 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m153.59375 97.394531h32v104h-32zm0 0" fill="#57a4ff"/>
    <path d="m153.59375 225.394531h32v32h-32zm0 0" fill="#57a4ff"/>
    <path d="m158.3125 326.113281-12.71875-12.71875v168h48v-168l-12.722656 12.71875c-2.988282 2.996094-7.046875 4.679688-11.277344 4.679688-4.234375 0-8.292969-1.683594-11.28125-4.679688zm0 0" fill="#57a4ff"/>
    <g fill="#004fac">
        <path d="m331.992188 152.457031-145.464844-145.460937c-9.363282-9.328125-24.507813-9.328125-33.871094 0l-145.464844 145.460937c-9.324218 9.367188-9.324218 24.507813 0 33.875l130.402344 130.375v164.6875c0 4.417969 3.582031 8 8 8h48c4.417969 0 8-3.582031 8-8v-164.6875l130.398438-130.398437c9.3125-9.363282 9.3125-24.488282 0-33.851563zm-178.398438 320.9375v-140.855469c9.042969 8.320313 22.953125 8.320313 32 0v140.855469zm167.0625-298.398437-145.464844 145.460937c-3.117187 3.082031-8.128906 3.082031-11.246094 0l-145.417968-145.460937c-3.078125-3.117188-3.078125-8.132813 0-11.25l145.464844-145.414063c3.109374-3.105469 8.140624-3.105469 11.25 0l145.414062 145.464844c3.050781 3.109375 3.050781 8.085937 0 11.199219zm0 0"/>
        <path d="m259.9375 201.804688-24 24c-2.082031 2.007812-2.914062 4.980468-2.183594 7.777343.734375 2.796875 2.917969 4.980469 5.714844 5.714844 2.796875.730469 5.769531-.101563 7.78125-2.183594l24-24c3.03125-3.136719 2.988281-8.128906-.097656-11.210937-3.085938-3.085938-8.078125-3.132813-11.214844-.097656zm0 0"/>
        <path d="m223.25 77.675781c-3.140625-3.03125-8.128906-2.988281-11.214844.097657-3.085937 3.085937-3.128906 8.074218-.097656 11.214843l80.398438 80.40625-16.398438 16.410157c-2.082031 2.007812-2.914062 4.980468-2.183594 7.777343.734375 2.796875 2.917969 4.980469 5.714844 5.714844 2.796875.730469 5.769531-.101563 7.78125-2.183594l22.054688-22.0625c3.121093-3.125 3.121093-8.1875 0-11.3125zm0 0"/>
        <path d="m185.59375 89.394531h-32c-4.417969 0-8 3.582031-8 8v104c0 4.417969 3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8v-104c0-4.417969-3.582031-8-8-8zm-8 104h-16v-88h16zm0 0"/>
        <path d="m185.59375 217.394531h-32c-4.417969 0-8 3.582031-8 8v32c0 4.417969 3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8v-32c0-4.417969-3.582031-8-8-8zm-8 32h-16v-16h16zm0 0"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "NotReadyIcon",

    data() {
        return { }
    }
};
</script>

<style>
</style>
