import { SET_NOTIFICATION, CLEAR_NOTIFICATION } from "./mutations.type";
import { TRIGGER_NOTIFICATION } from "./actions.type";

const state = {
    notification: {
        message: "",
        type: "",
        visible: false,
        timeout: -1
    }
};

const getters = {
    notification(state) {
        return state.notification;
    }
};

const actions = {
    async [TRIGGER_NOTIFICATION](context, { message, type, timeout }) {
        let wait = context.state.notification.visible ? timeout : 0;
        setTimeout(() => {
            context.commit(SET_NOTIFICATION, { message, type, timeout });
        }, wait);
    }
};

const mutations = {
    [SET_NOTIFICATION](state, { message, type, timeout }) {
        state.notification.message = message;
        state.notification.type = type;
        state.notification.visible = true;
        state.notification.timeout = timeout;
    },
    [CLEAR_NOTIFICATION](state) {
        state.notification.message = "";
        state.notification.type = "";
        state.notification.visible = false;
        state.notification.timeout = 0;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
