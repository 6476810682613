<template>
<svg viewBox="0 -21 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m382.671875 455c0 8.28125-6.710937 15-15 15h-223.34375c-8.289063 0-15-6.71875-15-15s6.710937-15 15-15h96.671875v-45h30v45h96.671875c8.289063 0 15 6.71875 15 15zm0 0" fill="#00b2ff"/>
    <path d="m382.671875 455c0 8.28125-6.710937 15-15 15h-111.671875v-75h15v45h96.671875c8.289063 0 15 6.71875 15 15zm0 0" fill="#0089ff"/>
    <path d="m512 47.101562v287.796876l-256 10-256-10v-287.796876c0-25.972656 21.128906-47.101562 47.101562-47.101562h417.796876c25.972656 0 47.101562 21.128906 47.101562 47.101562zm0 0" fill="#d1effa"/>
    <path d="m512 47.101562v287.796876l-256 10v-344.898438h208.898438c25.972656 0 47.101562 21.128906 47.101562 47.101562zm0 0" fill="#97e1ff"/>
    <g fill="#0051c6">
        <path d="m512 334.898438v28c0 25.972656-21.128906 47.101562-47.101562 47.101562h-417.796876c-25.972656 0-47.101562-21.128906-47.101562-47.101562v-28zm0 0"/>
        <path d="m256 88.75c-27.929688 0-50.648438 22.730469-50.648438 50.648438v71.3125c0 27.929687 22.71875 50.648437 50.648438 50.648437s50.648438-22.71875 50.648438-50.648437v-71.3125c0-27.917969-22.71875-50.648438-50.648438-50.648438zm20.648438 121.960938c0 11.390624-9.257813 20.648437-20.648438 20.648437s-20.648438-9.257813-20.648438-20.648437v-71.3125c0-11.378907 9.257813-20.648438 20.648438-20.648438s20.648438 9.269531 20.648438 20.648438zm0 0"/>
        <path d="m166.652344 88.753906c-8.285156 0-15 6.714844-15 15v56.304688h-30.667969c-5.867187 0-10.636719-4.773438-10.636719-10.640625v-45.664063c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v45.664063c0 22.410156 18.230469 40.640625 40.636719 40.640625h30.667969v56.304687c0 8.28125 6.714844 15 15 15s15-6.71875 15-15v-142.609375c0-8.285156-6.714844-15-15-15zm0 0"/>
    </g>
    <path d="m408.652344 88.753906c-8.285156 0-15 6.714844-15 15v56.304688h-30.667969c-5.867187 0-10.636719-4.773438-10.636719-10.640625v-45.664063c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v45.664063c0 22.410156 18.230469 40.640625 40.636719 40.640625h30.667969v56.304687c0 8.28125 6.714844 15 15 15s15-6.71875 15-15v-142.609375c0-8.285156-6.714844-15-15-15zm0 0" fill="#003183"/>
    <path d="m306.648438 139.398438v71.3125c0 27.929687-22.71875 50.648437-50.648438 50.648437v-30c11.390625 0 20.648438-9.257813 20.648438-20.648437v-71.3125c0-11.378907-9.257813-20.648438-20.648438-20.648438v-30c27.929688 0 50.648438 22.730469 50.648438 50.648438zm0 0" fill="#003183"/>
    <path d="m512 334.898438v28c0 25.972656-21.128906 47.101562-47.101562 47.101562h-208.898438v-75.101562zm0 0" fill="#003183"/>
</svg>
</template>

<script>
export default {
    name: "NotFoundIcon",

    data() {
        return { }
    }
};
</script>

<style>
</style>
