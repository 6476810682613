import Vue from "vue";
import Vuex from "vuex";

import post from "./post.module";
import meta from "./meta.module";
import notification from "./notification.module";
import social from "./social.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        post,
        meta,
        notification,
        social
    }
});
