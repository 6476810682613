<template>
    <v-app id="app">
        <v-overlay :value="loading" z-index="1000">
            <v-progress-circular :indeterminate="loading" color="secondary" :size="77" :width="12" aria-label="Loading" />
        </v-overlay>
        <Header />
        <v-main>
            <Notification />
            <fade-transition mode="out-in">
                <router-view />
            </fade-transition>
            <zoom-center-transition :duration="300">
                <v-btn 
                    v-scroll="onScroll"
                    v-show="fab"
                    large 
                    fab
                    fixed
                    bottom
                    right
                    outlined
                    @click="toTop()" 
                    title="Scroll To Top" 
                    class="pa-0"
                >
                    <v-icon class="footer-icon">{{ mdiArrowUpBoldOutline }}</v-icon>
                </v-btn>
            </zoom-center-transition>
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import { mdiArrowUpBoldOutline } from "@mdi/js";
import { FadeTransition, ZoomCenterTransition } from "vue2-transitions";

export default {
    name: "App",
    components: {
        Header: () => import("@/components/Header"),
        Footer: () => import("@/components/Footer"),
        Notification: () => import("@/components/Notification"),
        FadeTransition,
        ZoomCenterTransition
    },
    methods: { 
        onScroll(e) {
            if (typeof window === 'undefined') return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20 && this.$vuetify.breakpoint.smAndUp;
        },
        toTop() {
            this.$vuetify.goTo(0);
        }
    },
    data() {
        return {
            mdiArrowUpBoldOutline: mdiArrowUpBoldOutline,
            loading: true,
            fab: false
        }
    },
    created() {
        this.axios.interceptors.request.use(
            (config) => {
                this.loading = true;
                return config;
            }, 
            (error) => {
                this.loading = false;
                return Promise.reject(error);
            }
        );

        this.axios.interceptors.response.use(
            (response) => {
                setTimeout(() => { 
                    this.loading = false;
                }, 500);
                return response;
            },
            (error) => {
                if (error.response.status === 404) {
                    this.$router.push({ name: "not_found" });
                } else if (error.response.status === 500) {
                    this.$router.push({ name: "server_error" });
                }
                this.loading = false;
                return error.response;
            }
        );
    },
    mounted() {
        this.loading = false;
    }
};
</script>

<style lang="scss">
.v-application {
    font-family: "Raleway", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .v-card {
        font-family: "Raleway", sans-serif !important;
        .v-card__title {
            font-family: "Raleway", sans-serif !important;
            word-break: break-word;
        }
        .v-card__text {
            font-family: "Raleway", sans-serif !important;
            color: rgba($black, 1);
        }
    }
}

:root {
    color-scheme: light only;
}

.v-btn:hover {
    .facebook {
        fill: $facebook;
    }
    .youtube {
        fill: $youtube;
    }
    .instagram {
        fill: $black;
    }
    .twitter {
        fill: $twitter;
    }
    .pinterest {
        fill: $pinterest;
    }
}
</style>
