import Vue from "vue";
import Vuetify from "vuetify/lib";
import NotFoundIcon from "@/components/NotFoundIcon";
import NotReadyIcon from "@/components/NotReadyIcon";
import ServerErrorIcon from "@/components/ServerErrorIcon";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2196f3",
                secondary: "#A5D9FB",
                accent: "#03a9f4",
                error: "#f44336",
                warning: "#ffc622",
                info: "#00bcd4",
                success: "#4caf50"
            }
        },
        options: {
            // Inject vuetify color css variables into html
            // customProperties: true
        }
    },
    icons: {
        iconfont: "mdiSvg",
        values: {
            'notFoundIcon': { // name of our custom icon
                component: NotFoundIcon, // our custom component
            },
            'notReadyIcon': { // name of our custom icon
                component: NotReadyIcon, // our custom component
            },
            'serverErrorIcon': { // name of our custom icon
                component: ServerErrorIcon, // our custom component
            },
        },
    }
});
