/* eslint-disable no-unused-vars */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const maintenance = process.env.VUE_APP_UNDER_CONSTRUCTION === "true";
const preview_password = process.env.VUE_APP_PREVIEW_PASSWORD;
const beforeEnter = (to, from, next) => {
    maintenance && !(preview_password === to.query.preview)
        ? next({ name: "not_ready" })
        : next();
};

const router = new Router({
    mode: "history",
    base: "/home",
    scrollBehavior(to, from, savedPosition) {
        // Skip if destination is the same page as current page
        if (from.name == to.name) {
            return;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            name: "home",
            path: "/",
            component: () => import("@/views/Home"),
            beforeEnter: beforeEnter,
        },
        {
            name: "travel",
            path: "/travel",
            component: () => import("@/views/Travel"),
            beforeEnter: beforeEnter,
        },
        {
            name: "post",
            path: "/travel/:post_id",
            props: true,
            component: () => import("@/views/Post"),
            beforeEnter: beforeEnter,
        },
        {
            name: "photography",
            path: "/photography",
            component: () => import("@/views/Photography"),
        },
        {
            name: "about",
            path: "/about",
            component: () => import("@/views/About"),
        },
        {
            name: "not_found",
            path: "/not-found",
            component: () => import("@/views/NotFound"),
        },
        {
            name: "not_ready",
            path: "/under-construction",
            component: () => import("@/views/NotReady"),
        },
        {
            name: "server_error",
            path: "/error",
            component: () => import("@/views/ServerError"),
        },
    ],
});

export default router;
