<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <path style="fill:#53515E;" d="M477.486,424.797H34.514C15.452,424.797,0,409.345,0,390.283V38.488C0,19.427,15.452,3.974,34.514,3.974h442.973C496.548,3.974,512,19.427,512,38.488v351.795C512,409.345,496.548,424.797,477.486,424.797z"/>
    <path style="fill:#474756;" d="M364.402,339.5c-4.512,0-8.169-3.657-8.169-8.169V82.374c0-4.512,3.657-8.169,8.169-8.169s8.169,3.658,8.169,8.169v248.956C372.571,335.842,368.914,339.5,364.402,339.5z"/>
    <path style="fill:#57a4ff;" d="M477.486,3.974H34.514C15.452,3.974,0,19.427,0,38.488V86.97h509.662H512V38.488C512,19.427,496.548,3.974,477.486,3.974z"/>
    <path style="fill:#5728B2;" d="M323.799,54.227H171.836c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h151.963c4.512,0,8.169,3.658,8.169,8.169S328.311,54.227,323.799,54.227z"/>
    <circle style="fill:#FFDC64;" cx="45.589" cy="46.06" r="10.92"/>
    <circle style="fill:#86E56E;" cx="87.67" cy="46.06" r="10.92"/>
    <circle style="fill:#FFDC64;" cx="127.42" cy="46.06" r="10.92"/>
    <circle style="fill:#FF80BD;" cx="467.58" cy="46.06" r="10.92"/>
    <path style="fill:#474756;" d="M460.791,316.909H53.767c-17.436,0-31.571,14.135-31.571,31.571v74.045c3.827,1.463,7.977,2.272,12.318,2.272h442.973c5.327,0,10.372-1.209,14.876-3.364V348.48C492.363,331.044,478.228,316.909,460.791,316.909z"/>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="130.059" r="8.169"/>
    <path style="fill:#00AAF0;" d="M189.169,138.23H82.198c-4.512,0-8.169-3.658-8.169-8.169c0-4.512,3.657-8.169,8.169-8.169h106.971c4.512,0,8.169,3.658,8.169,8.169C197.338,134.574,193.681,138.23,189.169,138.23z"/>
    <g>
        <path style="fill:#3C3B44;" d="M478.499,130.061h-80.425c-4.512,0-8.169-3.658-8.169-8.169c0-4.512,3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.658,8.169,8.169C486.669,126.404,483.012,130.061,478.499,130.061z"/>
        <path style="fill:#3C3B44;" d="M478.499,165.189h-80.425c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.658,8.169,8.169S483.012,165.189,478.499,165.189z"/>
        <path style="fill:#3C3B44;" d="M478.499,200.316h-80.425c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.658,8.169,8.169S483.012,200.316,478.499,200.316z"/>
        <path style="fill:#3C3B44;" d="M478.499,235.445h-80.425c-4.512,0-8.169-3.658-8.169-8.169c0-4.512,3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.658,8.169,8.169C486.669,231.787,483.012,235.445,478.499,235.445z"/>
        <path style="fill:#3C3B44;" d="M478.499,270.573h-80.425c-4.512,0-8.169-3.657-8.169-8.169s3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.657,8.169,8.169C486.669,266.915,483.012,270.573,478.499,270.573z"/>
        <path style="fill:#3C3B44;" d="M478.499,305.7h-80.425c-4.512,0-8.169-3.657-8.169-8.169s3.657-8.169,8.169-8.169h80.425c4.512,0,8.169,3.657,8.169,8.169S483.012,305.7,478.499,305.7z"/>
    </g>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="164.369" r="8.169"/>
    <path style="fill:#00AAF0;" d="M138.336,172.542H82.198c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h56.138c4.512,0,8.169,3.658,8.169,8.169S142.848,172.542,138.336,172.542z"/>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="265.67" r="8.169"/>
    <path style="fill:#86E56E;" d="M166.711,273.84H82.198c-4.512,0-8.169-3.657-8.169-8.169s3.657-8.169,8.169-8.169h84.513c4.512,0,8.169,3.657,8.169,8.169S171.223,273.84,166.711,273.84z"/>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="299.17" r="8.169"/>
    <g>
        <path style="fill:#00AAF0;" d="M166.711,307.334H82.198c-4.512,0-8.169-3.657-8.169-8.169s3.657-8.169,8.169-8.169h84.513c4.512,0,8.169,3.657,8.169,8.169S171.223,307.334,166.711,307.334z"/>
        <path style="fill:#00AAF0;" d="M223.638,273.84h-29.843c-4.512,0-8.169-3.657-8.169-8.169s3.657-8.169,8.169-8.169h29.843c4.512,0,8.169,3.657,8.169,8.169S228.15,273.84,223.638,273.84z"/>
    </g>
    <path style="fill:#FFDC64;" d="M227.973,172.542h-56.137c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h56.137c4.512,0,8.169,3.658,8.169,8.169S232.485,172.542,227.973,172.542z"/>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="198.679" r="8.169"/>
    <path style="fill:#FF80BD;" d="M193.795,206.852h-77.514c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h77.514c4.512,0,8.169,3.658,8.169,8.169S198.307,206.852,193.795,206.852z"/>
    <circle style="fill:#AAA8C1;" cx="49.796" cy="231.359" r="8.169"/>
    <path style="fill:#FFDC64;" d="M257.657,239.529H116.281c-4.512,0-8.169-3.658-8.169-8.169c0-4.512,3.657-8.169,8.169-8.169h141.376c4.512,0,8.169,3.658,8.169,8.169C265.826,235.871,262.169,239.529,257.657,239.529z"/>
    <path style="fill:#86E56E;" d="M305.487,206.852h-77.514c-4.512,0-8.169-3.658-8.169-8.169s3.657-8.169,8.169-8.169h77.514c4.512,0,8.169,3.658,8.169,8.169S309.999,206.852,305.487,206.852z"/>
    <path style="fill:#FF6B5C;" d="M440.54,508.025H74.019c-15.701,0-28.43-12.728-28.43-28.43V364.355c0-15.701,12.728-28.43,28.43-28.43H440.54c15.701,0,28.43,12.728,28.43,28.43v115.239C468.97,495.296,456.241,508.025,440.54,508.025z"/>
    <path style="fill:#FF5450;" d="M440.54,335.927h-17.159l0,0c0,74.744-60.592,135.337-135.337,135.337H45.589v8.332c0,15.701,12.728,28.43,28.43,28.43H440.54c15.701,0,28.43-12.728,28.43-28.43v-115.24C468.97,348.655,456.241,335.927,440.54,335.927z"/>
    <g>
        <path style="fill:#FFFFFF;" d="M104.572,455.934v-70.32c0-1.698,0.737-3.007,2.215-3.931c1.476-0.922,3.247-1.384,5.315-1.384h39.424c1.698,0,2.99,0.739,3.876,2.215c0.885,1.477,1.329,3.211,1.329,5.205c0,2.142-0.462,3.951-1.384,5.427c-0.924,1.476-2.198,2.215-3.821,2.215h-29.678v18.715h16.611c1.623,0,2.896,0.664,3.821,1.993c0.922,1.329,1.384,2.917,1.384,4.761c0,1.7-0.442,3.212-1.329,4.541c-0.886,1.329-2.178,1.993-3.876,1.993h-16.611v18.826h29.678c1.623,0,2.896,0.738,3.821,2.215c0.922,1.476,1.384,3.285,1.384,5.425c0,1.993-0.444,3.729-1.329,5.206c-0.886,1.477-2.178,2.215-3.876,2.215h-39.424c-2.068,0-3.839-0.46-5.315-1.385C105.309,458.943,104.572,457.633,104.572,455.934z"/>
        <path style="fill:#FFFFFF;" d="M164.259,455.934v-70.431c0-1.402,0.498-2.62,1.494-3.655c0.997-1.033,2.271-1.55,3.821-1.55h22.923c18.162,0,27.242,7.9,27.242,23.698c0,11.517-4.468,19.01-13.399,22.48l13.51,24.474c0.369,0.517,0.554,1.182,0.554,1.993c0,2.142-1.163,4.172-3.489,6.091c-2.325,1.921-4.78,2.878-7.364,2.878c-2.585,0-4.429-1.068-5.537-3.21l-15.172-29.458h-7.309v26.689c0,1.699-0.85,3.009-2.548,3.93c-1.699,0.925-3.728,1.385-6.091,1.385c-2.364,0-4.393-0.46-6.091-1.385C165.107,458.943,164.259,457.633,164.259,455.934z M181.534,415.957h10.962c3.249,0,5.721-0.794,7.42-2.381c1.698-1.587,2.546-4.226,2.546-7.918c0-3.691-0.849-6.33-2.546-7.918c-1.7-1.587-4.172-2.381-7.42-2.381h-10.962V415.957z"/>
        <path style="fill:#FFFFFF;" d="M228.931,455.934v-70.431c0-1.402,0.498-2.62,1.494-3.655c0.997-1.033,2.271-1.55,3.821-1.55h22.923c18.162,0,27.242,7.9,27.242,23.698c0,11.517-4.468,19.01-13.399,22.48l13.51,24.474c0.369,0.517,0.554,1.182,0.554,1.993c0,2.142-1.163,4.172-3.489,6.091c-2.325,1.921-4.78,2.878-7.364,2.878c-2.585,0-4.429-1.068-5.537-3.21l-15.172-29.458h-7.309v26.689c0,1.699-0.85,3.009-2.548,3.93c-1.699,0.925-3.728,1.385-6.091,1.385c-2.364,0-4.393-0.46-6.091-1.385C229.779,458.943,228.931,457.633,228.931,455.934z M246.206,415.957h10.962c3.249,0,5.721-0.794,7.42-2.381c1.698-1.587,2.547-4.226,2.547-7.918c0-3.691-0.849-6.33-2.547-7.918c-1.7-1.587-4.172-2.381-7.42-2.381h-10.962V415.957z"/>
        <path style="fill:#FFFFFF;" d="M292.718,435.779v-29.346c0-9.005,2.473-15.614,7.419-19.822c4.945-4.208,11.518-6.312,19.712-6.312c8.267,0,14.875,2.104,19.822,6.312c4.945,4.208,7.419,10.817,7.419,19.822v29.346c0,9.008-2.474,15.614-7.419,19.822c-4.947,4.208-11.555,6.312-19.822,6.312c-8.195,0-14.767-2.104-19.712-6.312C295.191,451.393,292.718,444.788,292.718,435.779zM309.993,435.779c0,7.383,3.284,11.074,9.856,11.074c6.644,0,9.966-3.691,9.966-11.074v-29.346c0-7.381-3.322-11.073-9.966-11.073c-6.573,0-9.856,3.692-9.856,11.073V435.779z"/>
        <path style="fill:#FFFFFF;" d="M358.164,455.934v-70.431c0-1.402,0.498-2.62,1.495-3.655c0.997-1.033,2.271-1.55,3.821-1.55h22.923c18.162,0,27.242,7.9,27.242,23.698c0,11.517-4.468,19.01-13.399,22.48l13.51,24.474c0.369,0.517,0.554,1.182,0.554,1.993c0,2.142-1.163,4.172-3.489,6.091c-2.325,1.921-4.78,2.878-7.364,2.878c-2.585,0-4.429-1.068-5.537-3.21l-15.172-29.458h-7.309v26.689c0,1.699-0.85,3.009-2.548,3.93c-1.699,0.925-3.728,1.385-6.091,1.385c-2.364,0-4.393-0.46-6.091-1.385C359.012,458.943,358.164,457.633,358.164,455.934z M375.44,415.957h10.962c3.249,0,5.721-0.794,7.42-2.381c1.698-1.587,2.547-4.226,2.547-7.918c0-3.691-0.849-6.33-2.547-7.918c-1.7-1.587-4.172-2.381-7.42-2.381H375.44V415.957z"/>
    </g>
</svg>
</template>

<script>
export default {
    name: "ServerErrorIcon",

    data() {
        return { }
    }
};
</script>

<style>
.test {
    color: #5799ff
}
</style>
