const titleTemplate = "%s - Feel Travelling";

const meta = [
    {
        name: "description",
        content: "Explore our photos and stories and become a part of our journey as we travel around the world and discover its wonders."
    },
    { 
        name: "keywords", 
        content: "travel,blog,vacation" 
    },
    {
        "http-equiv": "Content-Type",
        content: "text/html; charset=utf-8"
    },
    { 
        name: "viewport", 
        content: "width=device-width, initial-scale=1" 
    }
]

const metaOpenGraph = [
    ...meta,
    {
        property: "og:title",
        content: "Feel Travelling"
    },
    {
        property: "og:description",
        content: "Explore our photos and stories and become a part of our journey as we travel around the world and discover its wonders."
    },
    {
        property: "og:image",
        content: "/static/img/main.jpg"
    }
];

const state = {
    home: {
        title: "Home",
        titleTemplate: titleTemplate,
        meta: [
            ...metaOpenGraph,
            {
                property: "og:url",
                content: "https://www.feeltravelling.com/home"
            }
        ],
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    travel: {
        title: "Travel",
        titleTemplate: titleTemplate,
        meta: [
            ...metaOpenGraph,
            {
                property: "og:url",
                content: "https://www.feeltravelling.com/home/travel"
            }
        ],
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    post: {
        title: "",
        titleTemplate: titleTemplate,
        meta: meta,
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    photography: {
        title: "Photography",
        titleTemplate: titleTemplate,
        meta: [
            ...metaOpenGraph,
            {
                property: "og:url",
                content: "https://www.feeltravelling.com/home/photography"
            }
        ],
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    about: {
        title: "About",
        titleTemplate: titleTemplate,
        meta: [
            ...metaOpenGraph,
            {
                property: "og:url",
                content: "https://www.feeltravelling.com/home/about"
            }
        ],
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    not_found: {
        title: "Page Not Found",
        titleTemplate: titleTemplate,
        meta: metaOpenGraph,
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    },
    not_ready: {
        title: "Page Under Construction",
        titleTemplate: titleTemplate,
        meta: metaOpenGraph,
        link: [
            {
                rel: "canonical",
                href: ""
            }
        ]
    }
};

const getters = {
    meta: state => (pageName, pageLink) => {
        var meta = state[pageName];
        meta.link[0].href = pageLink;
        return meta;
    },
    postMeta: state => (post, pageLink) => {
        var meta = state.post;
        meta.title = post.title;
        meta.link[0].href = pageLink;
        meta.meta = [
            ...meta.meta,
            {
                property: "og:url",
                content: pageLink
            },
            {
                property: "og:title",
                content: post.title
            },
            {
                property: "og:description",
                content: post.description
            },
            {
                property: "og:image",
                content: post.title_image
            }
        ]
        return meta;
    }
};

const actions = {};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
