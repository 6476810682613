import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";

import ApiService from "./common/api.service";
import ErrorFilter from "./common/error.filter";

Vue.config.productionTip = false;
Vue.filter("error", ErrorFilter);

ApiService.init();

Vue.use(VueMeta);

const mixin = {
    methods: {
        getTitleSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return "title"
                case "sm": return "headline"
                case "md": return "display-1"
                case "lg": return "display-1"
                case "xl": return "display-1"
            }
        },
        getBreakpointName() {
            return this.$vuetify.breakpoint.name;
        }
    }
}

Vue.mixin(mixin);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
