import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const API_URL = "/api";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  async query(resource, params) {
    return Vue.axios.get(`${resource}/`, { params });
  },

  async get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}/`.replace(/\/\//g, "/"));
  },

  async post(resource, params) {
    return Vue.axios.post(`${resource}/`, params);
  },

  async update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}/`.replace(/\/\//g, "/"), params);
  },

  async put(resource, params) {
    return Vue.axios.put(`${resource}/`, params);
  },

  async delete(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}/`.replace(/\/\//g, "/"));
  }
};

export default ApiService;
