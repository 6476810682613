import { mdiFacebook, mdiYoutube, mdiInstagram, mdiPinterest } from "@mdi/js";

const state = {
    social: [
        {
            class: "facebook",
            title: "Facebook",
            link: "https://www.facebook.com/feeltravelling/",
            icon: mdiFacebook
        },
        {
            class: "youtube",
            title: "Youtube",
            link: "https://www.youtube.com/channel/UCQWvtl01nmmZN2dVTiLn3gw/videos",
            icon: mdiYoutube
        },
        {
            class: "instagram",
            title: "Instagram",
            link: "https://instagram.com/feel.travelling",
            icon: mdiInstagram
        },
        {
            class: "pinterest",
            title: "Pinterest",
            link: "https://www.pinterest.com/feeltravelling/feel-travelling-posts/",
            icon: mdiPinterest
        }
    ]
};

const getters = {
    socialPages: state => state.social
};

const actions = {};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
